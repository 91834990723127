<section class="section" id="impactmodel">
  <div class="container impactmodel">
    <div class="section-box">
      <div class="about-title mb-5" data-aos="fade-up" data-aos-duration="1000">
        <h3 class="e-font section-title">
          <span class="code-font n-section-title">02.</span>
          {{ "Impactmodel.Title" | translate }}
        </h3>
        <p class="subtitle" [innerHTML]="'Impactmodel.Intro' | translate"></p>
      </div>
      <div class="impactmodel-tabs" data-aos="fade-up" data-aos-duration="1000">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" orientation="vertical" class="nav-tabs impactmodel-tabs">
          <li *ngFor="let tab of 'Impactmodel.Tabs' | translate; let i = index" [title]="tab.Tab" [ngbNavItem]="i">
            <a ngbNavLink>{{ tab.Tab }}</a>
            <ng-template ngbNavContent>
              <h4 class="title-tab-content">
                {{ tab.Title }}
                <span class="company-tab-content">{{ tab.Tab }}</span>
              </h4>
              <h5 class="tab-time">{{ tab.Date }}</h5>
              <p *ngFor="let tabDescriptionParagraph of tab.Description; let i = index" class="tab-description" [innerHTML]="tabDescriptionParagraph | translate"></p>
              <span></span>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</section>
