<section class="section about" id='about'>
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h1 class="section-title">
                    {{ rol+".Title" | translate}}
                </h1>
                <!-- <h4 class="vacature-subtitle">{{ rol+".Subtitle" | translate}}</h4> -->
            </div>
            
            <div class="row">
                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-img-container">
                        <img class="rounded" width="400" height="300" src="assets/images/like-man.png" alt="Dit zijn wij">
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor='let text of (rol + ".Intro") | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                        </p>
                    </div>
                </div>

            </div>
        </div>
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h1 class="section-title">
                    {{ rol+".SkillsTitle" | translate}}
                </h1>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-img-container">
                        <img class="rounded" width="400" height="300" src="assets/images/vergrootglas-man.png" alt="Dit zijn wij">
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor='let text of (rol + ".Skills") | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000" class="bullitpoint">
                        </p>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h1 class="section-title">
                    {{ rol+".ExpectationTitle" | translate}}
                </h1>
            </div>
            <ul>

            </ul>
            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor='let text of (rol + ".Expectation") | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000" class="bullitpoint">
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-img-container">
                        <img class="rounded" width="400" height="300" src="assets/images/money-man.png" alt="Dit zijn wij">
                    </div>
                </div>
            </div>
        </div>
        
        <div class="section-box">
            <div class="div-btn-banner vacature-end">
                <a href="contact" target="_black" class="main-btn">
                    {{"Banner.ActionBtn" | translate}}
                </a>
            </div>
        </div>
        
    </div>
</section>
