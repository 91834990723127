import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language/language.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})



export class HeaderComponent implements OnInit {

  responsiveMenuVisible: Boolean = false;
  pageYPosition: number;
  constructor(
    private router: Router,
    public languageService: LanguageService
  ) { }

  ngOnInit(): void {

  }

  scroll(el) {
    if(document.getElementById(el)) {
      document.getElementById(el).scrollIntoView({behavior: 'smooth'});
    } else{
      this.router.navigate(['/']).then(()=> document.getElementById(el).scrollIntoView({behavior: 'smooth'}) );
    }
    this.hideSideMenu();
  }

  hideSideMenu() {
    this.responsiveMenuVisible=false;
  }
}
