<section id="banner" class="section banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
                    <h1>{{"Banner.Pretitle" | translate}}</h1>
                </div>
                <div class='banner-title'>
                    <h3>Premium Impact</h3>
                </div>
                <div class='banner-description'>
                    <p *ngFor='let text of "Banner.Descriptions" | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000" class="mt-4">
                </div>
            </div>
        </div>
    </div>
</section>
