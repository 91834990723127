import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-vacaturedetail',
  templateUrl: './vacaturedetail.component.html',
  styleUrl: './vacaturedetail.component.scss'
})
export class VacaturedetailComponent implements OnInit{
  
  rol = "";
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
  }
  
  ngOnInit(): void {
    this.rol = this.activatedRoute.snapshot.paramMap.get('id');

  }
}
