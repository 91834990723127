import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-impactmodel',
  templateUrl: './impactmodel.component.html',
  styleUrls: ['./impactmodel.component.scss']
})
export class ImpactmodelComponent implements OnInit {
  
  active = 0
  
  ngOnInit(): void {
  }

}
