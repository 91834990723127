<section id="werkenbij" class="section werkenbij">
    <div class="container">
        <div class="section-box">
            <div class="">
                <h3 class="section-title">
                    <span class='n-section-title'>03.</span> {{"WerkenBij.Title" | translate}}
                </h3>
            </div>
            <div class="mt-5">
                <div class="row p-0">

                    <div *ngFor='let rol of "WerkenBij.rol" | translate; let i = index'
                     class="col-12 col-md-6 col-lg-6 col-xl-6 rol-col rounded" data-aos="fade-up" data-aos-duration="1000">
                        <div (click)="navigateToVacature(rol.Id)" class="rol-box">
                            <div class="row w-100 text-left m-0 p-0">
                                <div class="col-6 p-0">
                                    <img src='assets/icons/folder.svg' width="40">
                                </div>
                                <div class="col-6 d-flex p-0 justify-content-end">
                                    <a style='color: inherit' [href]="rol['linkedinurl']" target="_blank"><i id="ghLink" class="ml-4 fab fa-linkedin"></i></a>
                                    <a style='color: inherit' [href]="rol['emailurl']" target="_blank"><i id="ghLink" class="ml-4 fab fa fa-envelope"></i></a>
                                </div>
                                <h5 class="rol-title mt-4">{{rol.Title}}</h5>
                                <p class="rol-description">
                                    {{rol.Description}}
                                </p>
                            </div>
                            <div>
                                <ul class="werkenbij-skills">
                                    <li *ngFor='let technology of rol.Technologies'>
                                      <span class="underline">{{technology}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
