import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-werkenbij',
  templateUrl: './werkenbij.component.html',
  styleUrls: ['./werkenbij.component.scss']
})
export class WerkenbijComponent implements OnInit {

  constructor(
    private router: Router,
    ) { }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
    }

    navigateToVacature(id: number) {
      this.router.navigate(['/vacature', id]);
    }
}
