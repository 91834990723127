import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { VacaturedetailComponent } from './components/vacaturedetail/vacaturedetail.component';


const routes: Routes = [

  // {path: ':language?',      component: HomeComponent},
  {path: '',      component: HomeComponent},
  {path: 'vacature/:id', component: VacaturedetailComponent},
  {path: 'contact',      component: ContactComponent},
  {path: '**', pathMatch: 'full', redirectTo: '/'},

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
