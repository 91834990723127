<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-md-block">
            <li>
                <a href="https://www.linkedin.com/company/premium-impact/" target='_blank'>
                    <i class="fab fa-linkedin-in"></i>
                </a>
            </li>
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a href="mailto:info@premiumimpact.nl" target='_blank'>premiumimpact&#64;gmail.com </a>
        </div>
    </div>

    <div class="footer-credits text-center" >
        <a href="https://www.linkedin.com/company/premium-impact/" target="_blank" rel="nofollow noopener noreferrer">
            <div>Neem contact op</div>
        </a>
        <a href="https://www.linkedin.com/company/premium-impact/" target="_blank" rel="nofollow noopener noreferrer">
            <div class="mt-2">Premium Impact</div>
        </a>
    </div>

</footer>